import React, { useEffect, useState } from 'react';
import Spinner from '@components/shared/assets/bars-rotate-fade.svg';
import { useRouter } from 'next/router';
import CloseIcon from './close.svg';
import SearchIcon from './search.svg';

import styles from './SearchBar.module.scss';

interface ISearchBarProps {
  className?: string;
  text?: string;
  onClose?: () => void;
  onChange?: (value: string) => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  loading?: boolean;
}

export default function SearchBar({
  className,
  text,
  onChange,
  onSubmit,
  onClose
}: ISearchBarProps) {
  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (router.query.text && onChange) {
      onChange(router.query.text.toString());
    }
  }, []);

  return (
    <form
      className={`${styles.SearchBox} ${className || ''} ${focus ? styles.Focus : ''}`}
      onSubmit={async (e) => {
        if (onSubmit) {
          setLoading(true);
          await onSubmit(e);
          setLoading(false);
        }
      }}
    >
      <button title="Search" type="submit" className={`${styles.Search} ${styles.Mobile}`}>
        <SearchIcon />
      </button>

      <input
        type="text"
        placeholder="Search"
        value={text}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
      />

      <button title="Close" type="button" onClick={onClose} className={styles.Mobile}>
        <CloseIcon />
      </button>

      <LoaderSearchButton className={styles.Desktop} loading={loading} />
    </form>
  );
}

interface LoaderSearchButtonProps {
  loading?: boolean;
  className: string;
  text?: string;
  onClose?: () => void;
}

function LoaderSearchButton({
 loading, className, text, onClose
}: LoaderSearchButtonProps) {
  if (text) {
    return (
      <button title="Close" type="button" onClick={onClose}>
        <CloseIcon />
      </button>
    );
  }

  return (
    <div className={className}>
      {loading ? (
        <Spinner className={styles.Spinner} />
      ) : (
        <button title="Search" type="submit" className={styles.Search}>
          <SearchIcon />
        </button>
      )}
    </div>
  );
}
