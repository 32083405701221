import { IUser } from '@models/User/i-user';
import handleNavigationClick from '@utils/navigation/handleNavigationClick';
import classNames from 'classnames';
import React, { ReactElement, useContext } from 'react';
import ShopNavigationContext from '@components/shared/shop/ShopNavigationMenu/ShopNavigationContext';
import Account from '../../../Account/Account';
import Messaging from '../../../Messaging/Messaging';
import { accountLinks, customerLinks } from '../../components/NavLinks/links';
import NavLinks from '../../components/NavLinks/DesktopView/NavLinks';
import styles from './CustomerHeader.module.scss';
import Cart from '../../../Cart/Cart';

interface ICustomerHeaderProps {
  className?: string;
  user: IUser | null;
  isScrolled?: boolean;
  isTransparent: boolean;
  messagingRoom?: string;
  cartItemCount: number;
  unreadNotificationCount: number;
  hideMessaging?: boolean;
  hideNavLinks?: boolean;
  navLinksOverride?: () => ReactElement;
  cities?: string[];
}

const CustomerHeader = ({
  className,
  user,
  isTransparent,
  isScrolled,
  messagingRoom,
  cartItemCount,
  unreadNotificationCount,
  hideMessaging = false,
  hideNavLinks = false,
  navLinksOverride
}: ICustomerHeaderProps) => {
  const { shopNavigationData, hideShopSubMenu } = useContext(ShopNavigationContext);

  return (
    <nav className={classNames(
      className,
      styles.CustomerHeader,
      { [styles.Opaque]: !isTransparent }
    )}
    >
      <NavLinks
        navLinks={customerLinks(shopNavigationData, hideShopSubMenu)}
        hideNavLinks={hideNavLinks}
        navLinksOverride={navLinksOverride}
      />
      <div className={styles.ButtonsContainer}>
        <Account
          user={user}
          isScrolled={isScrolled}
          isTransparent={isTransparent}
          onClickNavItem={(title: string) => handleNavigationClick(title)}
          links={accountLinks}
        />

        {!hideMessaging && (
          <Messaging
            messagingRoom={messagingRoom}
            unreadNotificationCount={unreadNotificationCount}
          />
        )}

        <Cart
          cartItemCount={cartItemCount}
          isScrolled={isScrolled}
          isTransparent={isTransparent}
          onClickNavItem={(title: string) => handleNavigationClick(title)}
        />
      </div>
    </nav>
  );
};

export default CustomerHeader;
